import { get } from 'lib/storage'
import { fetchapi } from './fetch'
import { Suspense, lazy } from 'react'

export function isLoggedIn() {
	return (async () => {
		const profile = await get('Profile')

		return !!(profile?.id)
	})()
}

export function isAdmin() {
	return (async () => {
		const profile = await get('Profile')

		if (profile?.id) {
			return !!(profile.isAdmin)
		} else {
			return false
		}
	})()
}

export async function sendEmail(emailid, to) {
	if (emailid && to?.length > 0) {
		const response = await fetchapi(`/admin/email/${emailid}/send`, {
			recipients: JSON.stringify(to)
		})

		if (response?.success) {
			return true
		} else {
			return false
		}
	}
}

export const pathPrefix = window.cordova ? '.' : ''
export const isNative = !!(window.cordova)

export function openBrowser(url, target, options) {
	if (isNative) {
		window.cordova?.InAppBrowser?.open(url, target, options)
	} else {
		window.open(url, target, options)
	}
}

export function lazyComponent(name, props) {
	const LazyComponent = lazy(() => lazyRetry(() => import(`../${name}`)))

	return (
		<Suspense>
			<LazyComponent {...props} />
		</Suspense>
	)
}

const lazyRetry = (componentImport) =>
	new Promise((resolve, reject) => {
		const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
		componentImport()
			.then((component) => {
				window.sessionStorage.setItem(storageKey, 'false');
				if (component === undefined) {
					window.sessionStorage.setItem(storageKey, 'true');
					return window.location.reload(); // refresh the page
				}
				resolve(component);
			})
			.catch((error) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem(storageKey, 'true');
					window.location.reload();
				}
				reject(error); // Default error behaviour as already tried refresh
			});
	});

export function getBuildId() {
	return process.env.REACT_APP_VERCEL_URL?.split('-')?.[1] || 'null'
}